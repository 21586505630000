import React, { type FC } from 'react';
import { type FormValues } from '@wix/form-viewer/dist/types/types';
import { CustomDialog, IconButton, TextArea } from 'wix-ui-tpa/cssVars';
import { CloseSmall } from '@wix/wix-ui-icons-common/on-stage';
import styles from './form-result-modal.scss';

interface FormResultModalProps {
  isOpen: boolean;
  closeModal: () => void;
  values?: FormValues;
  errors?: any[];
}

const FormResultModal: FC<FormResultModalProps> = ({
  isOpen,
  closeModal,
  values,
  errors,
}) => {
  return (
    <CustomDialog isOpen={isOpen} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.closeButton}>
          <IconButton icon={<CloseSmall />} onClick={closeModal} />
        </div>

        <div className={styles.content}>
          <TextArea
            className={styles.textArea}
            ariaLabel="values"
            label="Values"
            withResize
            value={JSON.stringify(values, null, 2)}
            onChange={() => {}}
          />
          <TextArea
            className={styles.textArea}
            ariaLabel="errors"
            label="Errors"
            withResize
            value={JSON.stringify(errors, null, 2)}
            onChange={() => {}}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default FormResultModal;
