import React, { type FC, type ReactNode } from 'react';
import { Text, TextButton, type TextProps } from 'wix-ui-tpa/cssVars';

import styles from './form-result-text.scss';

const TextWithChildren = Text as unknown as FC<
  TextProps & { children: ReactNode }
>;

interface FormResultTextProps {
  errors: any[];
  handleClick: () => void;
}

const FormResultText: FC<FormResultTextProps> = ({ errors, handleClick }) => {
  const result = errors?.length
    ? { text: 'Form encountered some errors. ', className: styles.error }
    : { text: 'Form submitted successfully! ', className: styles.success };

  return (
    <div className={styles.container}>
      <TextWithChildren className={result.className}>
        {result.text}
      </TextWithChildren>
      <TextButton onClick={handleClick}>Check data</TextButton>
    </div>
  );
};

export default FormResultText;
